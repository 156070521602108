<template>
		<div class="bullet-container">
			<v-overlay
				:opacity="0.15"
				:value="loader"
				absolute
			>
				<v-row>
					<v-col class="text-center">
						<v-progress-circular
							color="primary"
							indeterminate
							size="40"
							width="6"
						/>
					</v-col>
				</v-row>
			</v-overlay>
			<v-card elevation="6" :style="'background-color: ' + this.bgColor">
				<v-card-text class="pa-3">
					<div class="chart-label text-center" :style="cssLabelVars">{{ chartLabel }}</div>
					<div class="target-label-container">
						<div class="target-label" :style="cssTargetVars" ref="targetValueLabel">{{ '$' + formatThisNumber(targetValue,'0,0') }}</div>
					</div>
					<div class="column-container">
						<template v-if="colors">
							<div class="data-ranges d-flex flex-row">
								<div class="data-range" v-for="(dataRange, index) in dataRanges" :style="cssRangeVars(index)"/>
								<div class="data-range" :style="cssVars(dataRanges.length - 1)"/>
							</div>
						</template>
						<div class="data-column" :style="cssDataVars" ref="dataColumn" @click="openGrossMarginDetails"/>
						<div class="data-column-label" :style="cssDataVars" ref="dataColumnLabel">{{ '$' + formatThisNumber(currentValue,'0,0') }}</div>
						<div class="target-marker" :style="cssTargetVars"/>
					</div>
					<div class="error-container" v-if="loadingError">
						<v-alert dense outlined type="error" class="mt-3 mb-0">{{ errorMessage }}</v-alert>
					</div>
				</v-card-text>
			</v-card>
		</div>
</template>

<script>
import {log, numberFormat} from "Helpers/helpers";
import {api} from 'Api';

export default {
	name: "QuarterGrossMargin",
	props: ['conditions','color','report','isCurrentQuarter'],
	data() {
		return {
			bgColor: null,
			bulletWidth: 200,
			colors: [],
			currentValue: 0,
			dataColumnColor: null,
			dataColumnLabelWidth: '10px',
			dataColumnWidth: '0px',
			errorMessage: null,
			grossMarginDetailsDialog: false,
			label: '',
			labelColor: '#ffffff',
			loader: true,
			loadingError: false,
			maxValue: 0,
			ranges: [],
						rangeWidths: [],
			reverseTextColors: [
				'cyan',
				// 'light-green',
				// 'blue-grey',
				// 'green',
				'lime',
				'yellow',
				'amber',
				// 'orange'
			],
			targetValue: 0,
			targetValueLabelWidth: 50
		}
	},
	computed: {
		chartLabel() {
			if(this.label != null) return this.label
			return this.$t('message.chartLabel')
			},
		cssDataVars() {
			let dataColumnWidth = '100%'
			let dataColumnLabelLeft = '50%'
			let dataColumnLabelColor = '#ffffff'
			if(this.reverseTextColors.includes(this.color)){
				dataColumnLabelColor = '#000000'
			}
			if(this.currentValue != null && this.maxValue != null){
				if(this.currentValue == 0){
					dataColumnWidth = '0%'
				} else {
					dataColumnWidth = (Math.floor((this.currentValue / this.maxValue) * 100)).toString() + '%'
				}
				if(this.currentValue < this.targetValue * 0.7){
					dataColumnLabelLeft = 'calc(' + dataColumnWidth + ' + ' + '5px' + ')'
				} else {
					dataColumnLabelLeft = 'calc(' + dataColumnWidth + ' - ' + ( this.dataColumnLabelWidth + 45 ) + 'px' + ')'
				}
			}
			return {
				'--data-column-width': dataColumnWidth,
				'--data-column-label-left': dataColumnLabelLeft,
				'--data-column-label-color': dataColumnLabelColor
			}
		},
		cssLabelVars() {
			let chartLabelColor = '#ffffff'
			return {
				'--chart-label-color': chartLabelColor
			}
		},
		cssTargetVars() {
			let targetLabelColor = '#ffffff'
			let targetMarkerLeft = '50%'
			let targetLabelLeft = '50%'
			let centerLabel = this.targetValueLabelWidth
			if(this.targetValue != null && this.maxValue != null){
				let total = 0
				this.dataRanges.forEach(dr => {
					total += Math.floor((dr / this.maxValue) * 100)
				})
				targetMarkerLeft = total.toString() + '%'
				if(this.targetValue / this.maxValue < 1){
					centerLabel = this.targetValueLabelWidth / 2
				}
				targetLabelLeft = 'calc(' + targetMarkerLeft + ' - ' + centerLabel + 'px)'
			}
			return {
				'--target-label-color': targetLabelColor,
				'--target-label-left': targetLabelLeft,
				'--target-marker-left': targetMarkerLeft
			}
		},
		dataRanges() {
			if(this.ranges != null && this.ranges != []) return this.ranges
			return [
				'range1','range2','range3'
			]
		},
		targetLabel() {
			if(this.targetValue != null) return this.targetValue
			return 0
		}
	},
	methods: {
		cssVars(rangeIndex) {
			let exceedDataRangeWidth = 100
			let totalDataRangeWidth = 0
			if(this.maxValue != null && this.dataRanges != null) {
				this.dataRanges.forEach(dr => {
					totalDataRangeWidth += Math.floor((dr / this.maxValue) * 100)
				})
				exceedDataRangeWidth = exceedDataRangeWidth - totalDataRangeWidth
			}
			if(this.isCurrentQuarter){
				return {
					'--data-range-width': exceedDataRangeWidth.toString() + '%',
					'--data-range-color': this.colors[3] != null ? this.colors[3] : null
				}
			} else {
				return {
					'--data-range-width': exceedDataRangeWidth.toString() + '%',
					'--data-range-color': rangeIndex != null ? this.colors[rangeIndex] : null
				}
			}
		},
		cssRangeVars(rangeIndex) {
			let dataRangeWidth = '30%'
			if(this.maxValue != null && this.dataRanges[rangeIndex] != null) {
				dataRangeWidth = (Math.floor((this.dataRanges[rangeIndex] / this.maxValue) * 100)).toString() + '%'
			}
			return {
				'--data-range-width' : dataRangeWidth,
				'--data-range-color' : this.colors[rangeIndex]
			}
		},
		formatThisNumber (value,format) {
			return numberFormat(value,format)
		},
		openGrossMarginDetails() {
			this.grossMarginDetailsDialog = true
		},
		getValues() {
			return new Promise((resolve, reject) => {
				const url = '/sparkline/bullet/' + this.report
				if(url) {
					api
						.get(url, {
							params: {
								conditions: this.conditions ? this.conditions : []
							}
						})
						.then((response) => {
							const data = response.data.data
							resolve(data)
						})
						.catch((error) => {
							reject(error)
						})
				} else {
					reject('error')
				}
			})
		}
	},
	mounted() {
		switch(this.color){
			case 'light-green':
				this.bgColor = '#4ea64c'
				this.colors = ['#2a5829','#336b32','#397838']
				break;
			case 'salmon':
				this.bgColor = '#ee8953'
				this.colors = ['#82A05A','#82A05A','#82A05A','#11B1CC']
				break;
		}
		this.getValues()
			.then((data) => {
				this.ranges = data.ranges
				this.targetValue = data.targetValue
				this.currentValue = data.currentValue
				this.maxValue = data.maxValue
				this.label = data.label
				this.loader = false
			})
			.catch((error) => {
				this.loader = false
				this.loadingError = true
				this.errorMessage = error
			})
		this.$nextTick(() => {
			// const targetValueLabelElement = this.$refs.targetValueLabel
			const dataColumnLabelElement = this.$refs.dataColumnLabel
			const dataColumnElement = this.$refs.dataColumn
			// this.targetValueLabelWidth = targetValueLabelElement.offsetWidth
			this.dataColumnLabelWidth = dataColumnLabelElement.offsetWidth
			this.dataColumnWidth = dataColumnElement.offsetWidth
		})
	}
}
</script>

<style scoped>
.bullet-container {
	position: relative;
}
.chart-label {
	font-weight: bold;
	font-size: 1.25em;
	color: var(--chart-label-color);
}
.data-column {
	margin-top: 7.5px;
	cursor: pointer;
	height: 25px;
	position: absolute;
	width: var(--data-column-width);
	background: rgba(20, 20, 20, 0.7);
}
.data-column-label {
	margin-top: 10px;
	position: absolute;
	left: var(--data-column-label-left);
	color: var(--data-column-label-color);
}
.data-ranges {
	position: absolute;
	width: 100%;
}
.data-range {
	height: 40px;
	width: var(--data-range-width);
	background-color: var(--data-range-color);
}
.column-container {
	position: relative;
	min-height: 40px !important;
}
.target-label {
	color: var(--target-label-color);
	left: var(--target-label-left);
	position: absolute;
}
.target-label-container{
	position: relative;
	min-height: 25px !important;
}
.target-marker {
	width: 2px;
	position: absolute;
	background-color: black;
	height: 30px;
	margin-top: 5px;
	left: var(--target-marker-left);
}
.v-application .green.lighten-1  {
	background-color: #3C6932 !important;
}
.v-application .mid-green.lighten-1  {
	background-color: #82A05A !important;
}
.v-application .orange.lighten-1  {
	background-color: #F08700 !important;
}
</style>